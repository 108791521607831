@use "../../styles/base/colors" as colors;

.project-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 0.875rem;

  &--prev a,
  &--next a {
    display: flex;
    align-items: center;
    color: colors.$web-primary-400;
    text-decoration: none;

    svg {
      color: inherit;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &--prev a svg {
    margin-right: 0.5rem;
  }

  &--next a svg {
    margin-left: 0.5rem;
  }
}