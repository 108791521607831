@use "sass:list";
@use "sass:map";
@use "colors" as colors;
/* Font Variables  */
$font-family-root: Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji"; 
$font-family-root-light: "", sans-serif, system-ui, ui-sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
// Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
$font-family-root-heavy: Inter, sans-serif, system-ui, ui-sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";  
/* Provisional Font style for texts more legible */
$font-family-root-text: Inter,ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";

/* Colors Variables */
$colors: (
  "web-neutral-0":  colors.$web-neutral-0,
  "web-neutral-10": colors.$web-neutral-10,
  "web-neutral-50": colors.$web-neutral-50,
  "web-neutral-100": colors.$web-neutral-100,
  "web-primary-10": colors.$web-primary-10,
  "web-primary-300": colors.$web-primary-300,
  "web-primary-400": colors.$web-primary-400,
  "web-primary-500": colors.$web-primary-500,
  "web-primary-600": colors.$web-primary-600,
  "web-primary-700": colors.$web-primary-700
);

@for $i from 1 through list.length($colors) {
    $color: list.nth(map.keys($colors), $i);
    $value: map.get($colors, $color);
    .#{$color} {
        color: $value;
    }
    .bg-#{$color} {
        background-color: $value;
    }
}

/* Spacing Variables */
// .py-32 {
//     padding-top: 8rem;
//     padding-bottom: 8rem;
// }
@for $i from 0 through 32 {
    .py-#{$i} {
      padding-top: $i * 0.25rem;
      padding-bottom: $i * 0.25rem;
    }
  }
