/**
 * Web Colors
 *
 * -------------------------------------------------------------------
 */

/* Neutral Colors */
$web-neutral-0: #fff;
$web-neutral-10: #f8f8f9;
$web-neutral-50: #fffef9cc;
$web-neutral-100: #1C2033;
// $web-neutral-50: #eceeef;
// $web-neutral-100: #d9dcdf;
// $web-neutral-200: #b3b8be;
// $web-neutral-300: #8c959e;
// $web-neutral-400: #66717d;
// $web-neutral-500: #404e5d;
// $web-neutral-600: #303b46;
// $web-neutral-700: #20272f;
// $web-neutral-800: #101417;
// $web-neutral-900: #060809;

/* Primary Colors */
$web-primary-10: #f5f8fb;
$web-primary-300: #6691bf;
$web-primary-400: #DAFFFB;
$web-primary-500: #39E6D4; //#64CCC5
$web-primary-600: #176B87;
$web-primary-700: #060918;
 

