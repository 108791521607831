@use "../base/colors" as colors;
@use "../base/variables" as var;

.about-container {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.left-container {
  &__img {
    &--about {
      background: url("../../assets/img/fondo-main-panel-1.webp") top center no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      padding: 90px 0px 25px 0px;
      height: 100vh;
      height: 100svh;
    }

    &--work {
      display: flex;
      justify-content: center;
      background: black;
      background-size: cover;
      flex-direction: column;
      justify-content: center;
      padding: 3% 0px 18px 0px;
    }
  }
}

.right-container {
  background-color: colors.$web-primary-700;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  &__text {
    padding-inline-start: 9%;

    @media (max-width: 1024px) {
   
      margin-inline: auto;
      width: 91.666667%;
      max-width: 112rem;
    }

    @media (min-width: 1024px) {

      padding-inline-start: 2.5rem;
    }

    @media (min-width: 1280px) {

      padding-inline-start: 5rem;
    }

    @media (max-width: 1530px) {

      padding-inline-start: 2rem;
      padding-inline-end: 2%;
    }

    p {
      margin: 0;
    }
  }

  &-about {
    &__icon--hi {
      color: colors.$web-primary-400;
      font-family: Consolas;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 2.4px;
      padding: 10px 10px 10px 0px;
    }

    &__text {

      &--container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        gap: 0.5rem;

      }

      &--name {
        color: colors.$web-neutral-0;
        font-family: "Saiba 45 Regular";
        font-size: clamp(1.5rem, 2vw, 2rem);
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0px;
        height: auto;
        line-height: normal;
        text-shadow: -3px -3px 0px #1df2f0, 3px 3px 0px #e94be8;
        background-color: transparent;
        white-space: nowrap;
        width: fit-content;
        display: inline-block;

      }

      &--im {
        color: colors.$web-primary-400;
        font-size: calc(0.3rem + 1vw);
        font-style: normal;
        font-family: var.$font-family-root-text;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.36px;
        text-shadow: none;
        padding-right: 6px;
      }



      &--description {
        max-width: 64ch;
        font-style: normal;
        letter-spacing: 0.18px;
        color: colors.$web-neutral-0;
        font-size: 1rem;
        line-height: 22px;
        width: 100%;

        @media (max-width: 768px) {
          font-size: calc(0.8rem + 1vw);
        }

        &-title {
          color: colors.$web-primary-400;
          font-style: normal;
          font-weight: bold;
          text-align: justify;
          font-size: 1.1rem;
          line-height: 22px;
          max-width: max-content;

          @media (max-width: 1530px) {

            //       padding-inline-start: 9%;
            //  padding-inline-end: 2%;
            // max-width: 80%;
          }
        }
      }
    }
  }

  &-work {
    &__text {
      &--title {
        color: colors.$web-primary-500;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.54px;
        font-family: Inter;
        // padding: 10px 7% 0 65px;
        padding: 2% 0px 0px 60px;
      }

      &--time {
        color: colors.$web-primary-400;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        padding: 12px 0px 0px 64px;
      }

      &--description {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        line-height: 30px;
        letter-spacing: 0.18px;
        padding: 17px 7% 0px 65px;
        color: colors.$web-neutral-10;
      }

      &--stack {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        line-height: 30px;
        letter-spacing: 0.18px;
        padding: 25px 7% 25px 65px;

        color: colors.$web-neutral-50;
      }
    }
  }
}

.home-page {
  .left-container {
    &__img {
      &--work {
        &:nth-of-type(1) {
          order: 2;
        }
      }
    }
  }

  .right-container {
    &:nth-of-type(1) {
      order: 2;
    }
  }
}

@keyframes glitch_4011 {
  0% {
    clip-path: var(--move1);
    transform: translate(0px, -10px);
  }

  10% {
    clip-path: var(--move2);
    transform: translate(-10px, 10px);
  }

  20% {
    clip-path: var(--move3);
    transform: translate(10px, 0px);
  }

  30% {
    clip-path: var(--move4);
    transform: translate(-10px, 10px);
  }

  40% {
    clip-path: var(--move5);
    transform: translate(10px, -10px);
  }

  50% {
    clip-path: var(--move6);
    transform: translate(-10px, 10px);
  }

  60% {
    clip-path: var(--move1);
    transform: translate(10px, -10px);
  }

  70% {
    clip-path: var(--move3);
    transform: translate(-10px, 10px);
  }

  80% {
    clip-path: var(--move2);
    transform: translate(10px, -10px);
  }

  90% {
    clip-path: var(--move4);
    transform: translate(-10px, 10px);
  }

  100% {
    clip-path: var(--move1);
    transform: translate(0);
  }
}

.static-text {
  font-family: "Saiba 45 Regular", sans-serif;

  color: #ffffff;
  font-size: calc(1rem + 1vw);
  font-weight: 900;
}

.glitch {
  font-family: "SAIBA-45", sans-serif;
  position: relative;
  font-size: calc(2rem + 1vw);
  padding-right: 4px;
  font-weight: 700;
  line-height: 1.2;
  color: #060918;
  letter-spacing: 5px;
  z-index: 1;
  animation: shift 1s ease-in-out infinite alternate;
  display: inline-block;
}

.glitch:before,
.glitch:after {
  display: block;
  content: attr(data-glitch);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.glitch:before {
  animation: glitch 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  color: #8b00ff;
  z-index: -1;
}

.glitch:after {
  animation: glitch 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
  color: #00e571;
  z-index: -2;
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-3px, 3px);
  }

  40% {
    transform: translate(-3px, -3px);
  }

  60% {
    transform: translate(3px, 3px);
  }

  80% {
    transform: translate(3px, -3px);
  }

  to {
    transform: translate(0);
  }
}

@keyframes shift {

  0%,
  40%,
  44%,
  58%,
  61%,
  65%,
  69%,
  73%,
  100% {
    transform: skewX(0deg);
  }

  41% {
    transform: skewX(10deg);
  }

  42% {
    transform: skewX(-10deg);
  }

  59% {
    transform: skewX(40deg) skewY(10deg);
  }

  60% {
    transform: skewX(-40deg) skewY(-10deg);
  }

  63% {
    transform: skewX(10deg) skewY(-5deg);
  }

  70% {
    transform: skewX(-50deg) skewY(-20deg);
  }

  71% {
    transform: skewX(10deg) skewY(-10deg);
  }
}

///animation Hi
.about {
  &__card--hi {
    --bg-color: #060918;
    background-color: var(--bg-color); 
    border-radius: 1.25rem;
  }

  &__container--hi {
    color: rgb(124, 124, 124);
    font-weight: 500;
    font-size: 25px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
  }
}

.words {
  overflow: hidden;
  position: relative;
}

.words::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(var(--bg-color) 10%,
      transparent 30%,
      transparent 70%,
      var(--bg-color) 90%);
  z-index: 20;
}

.word {
  display: block;
  height: 100%;
  color: #39e6d4;
  animation: spin_4991 8s infinite;
}

@keyframes spin_4991 {
  10% {
    -webkit-transform: translateY(-102%);
    transform: translateY(-102%);
  }

  25% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  35% {
    -webkit-transform: translateY(-202%);
    transform: translateY(-202%);
  }

  50% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  60% {
    -webkit-transform: translateY(-302%);
    transform: translateY(-302%);
  }

  75% {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  85% {
    -webkit-transform: translateY(-402%);
    transform: translateY(-402%);
  }

  100% {
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
}