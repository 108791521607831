@use "../../styles/base/colors" as colors;
 

.scroll-indicator {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .scroll-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    animation: smoothBounce 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    
    i {
      font-size: 2rem;
      background: transparent;
      color: colors.$web-primary-400;
      border-radius: 50%;
    }
  }
}

@keyframes smoothBounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}