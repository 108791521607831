/** Import Bootstrap SCSS files 
*
* Temporal If i need to import all bootstrap files
*/
// Sobrescribir la variable de Bootstrap
@use "../base/colors" as colors;
// @use "node_modules/bootstrap/scss/bootstrap.scss"; 
@use "node_modules/bootstrap-icons/font/bootstrap-icons.css";
// variables Boostrap 
:root {
    --bs-body-bg: #{colors.$web-primary-700};

  }
  
