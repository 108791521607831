  
@use "../base/colors" as colors;
 

.web-space{
    background-color: colors.$web-primary-700;
    &-title {
        font-size: calc(4rem + 3.9vw);
        color: colors.$web-primary-400;
        font-weight: 300;
        line-height: 100%;
      letter-spacing: 0.1rem;
    }
}


.web-space-subtitle {
    font-size: 1.5rem;
    font-family: 'Inter';
    font-weight: 300;
    line-height: 150%;
    color: #DAFFFB;
    opacity: 0.6;

}

.web-laptop-img {
  border-radius: 0.5rem;
}

.web-section-title {
  font-size: 1.75rem;
  border-left: 5px solid colors.$web-primary-400;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
/* Modern and visually appealing website that reflected the client's brand. */

 
