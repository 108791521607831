@use "../base/colors" as colors;
@use "../base/variables" as var;
.main-root {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.main-page {
  display: block;
}

.main-layout-route {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.main-layout-route-content {
  position: static;
  // min-height: 100svh;
  z-index: 10;
  inset: 0;
  // top: 1005px;
}

.main-page-content {
  background-color: transparent;
  width: 100%;
  height: fit-content;
  position: relative;
}

.main-page-item {
  padding: 32px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 2px 2px 16px 2px rgba(1, 9, 17, 0.05);
}

.flex-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &--page {
    flex-grow: 1;
    background: transparent;
    // height: 860px; //provisional whit no content
  }
}

.p-sticky {
  position: sticky;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.overview-button {
  width: 300px;
}

.overview-text-empty {
  max-width: 450px;
}

.overview-font-size {
  font-size: 0.8rem;
}

.padding-top-50 {
  padding-top: 50px;
}

.outer-container {
  display: flex;
  flex-wrap: wrap;
}

