@use "../base/variables" as var;
@use "../base/colors" as colors; 

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width:  calc(6.5rem + 5vw);
  overflow: hidden;
  height: calc(1.5rem + 2.5vh);
  height: calc(1.5rem + 2.5svh);
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 1rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#001153, #001153), linear-gradient(137.48deg, #9fdaff 10%, colors.$web-primary-700 45%, #9fdaffc9 67%, #001153 87%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0px;
  --glow-color: #000829;
  --enhanced-glow-color: colors.$web-primary-700;
  box-shadow: 0 0 1em .25em var(--glow-color)
    inset 0 0 .75em .25em var(--glow-color);
  text-shadow: 0 0 .5em var(--glow-color);
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.4s;
  backdrop-filter: blur(1rem);
  border-radius: 1rem;
}

strong {
  z-index: 2;
  // font-family: 'Open sans';
  font-size: calc(0.75rem + 0.4vw);
  letter-spacing: 4px;
  color: colors.$web-neutral-0;
  text-shadow: 0 0 4px colors.$web-neutral-0;
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 15s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: #010413;
}

.circle:nth-of-type(2) {
  background: #9fdaff86;
}

.btn:hover #container-stars {
  z-index: 1;
  background-color: colors.$web-primary-700;
}

.btn:hover {
  transform: scale(1.1);
}

.btn:active {
  border: double 4px colors.$web-primary-400;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;

}

.btn:active .circle {
  background: colors.$web-primary-400;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(colors.$web-neutral-0 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(colors.$web-neutral-0 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


//download button

.button-download {
  position: relative;
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: colors.$web-primary-700; 
    padding: 0;
  overflow: hidden;
  border: none;
  font-family: Inter;
}

.button-download,
.button-download__icon,
.button-download__text {
  transition: all 0.3s;
}

.button-download .button-download__text {
  transform: translateX(0);
  color: colors.$web-primary-400;

}

.button-download .button-download__icon {
  position: absolute;
  height: 100%;
  width: 20px;
  background-color: colors.$web-primary-700;

  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(100px);
}

.button-download .svg {
  width: 20px;
  fill: colors.$web-primary-500;

}

.button-download:hover {
  background: colors.$web-primary-700;
}

.button-download:hover .button-download__text {
  color: transparent;

}

.button-download:hover .button-download__icon {
  width: 100%;
  transform: translateX(-20px);
}

.button-download:active .button-download__icon {
  svg {
    fill: colors.$web-primary-400;
    filter: drop-shadow(0 0 4px colors.$web-neutral-0);
  }

}

.button-download:active {
  color: colors.$web-primary-400;

}

//modify primeNG button
:is(button, p-button) {
  &.p-button {
    .p-button-label {
      font-family: var.$font-family-root;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }

    &:focus {
      box-shadow: none;
    }

    &.p-button-secondary:enabled:focus {
      box-shadow: none;
    }
  }
}
.main-panel--button {
  &:not(.p-button-outlined, .p-button-text, .nav-item-home, p-button) {
    display: flex; 
    text-align: center; 
    font-size: 24px;
    font-style: normal;
    font-weight: 700; 
    color: #dafffb;
    border-radius: 8px;
    border: 1px solid colors.$web-primary-400;
    background: colors.$web-primary-700;
    padding: 12px 16px; 

    &:enabled:hover {
      background: colors.$web-primary-700;
      color: colors.$web-primary-400;
      border: 1px solid colors.$web-primary-700;
      box-shadow: inset 0px 0 6px 1px colors.$web-primary-400;
    }

    &:enabled:active {
      background: colors.$web-primary-400;
      color: colors.$web-primary-700;
      border: 1px solid colors.$web-primary-700;
      box-shadow: 0px 0 9px 1px colors.$web-primary-400;
    }
    &:focus {
      border: 1px solid colors.$web-primary-400;
      box-shadow: 0px 0 9px 1px colors.$web-primary-400;
    }
    &:disabled {
      background: colors.$web-primary-500;
      border: 1px solid colors.$web-primary-500;
      opacity: 0.6;
    }
  }
}
