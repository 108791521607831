@use "variables" as var;
@use "colors" as colors;
// Reset browser styles
html {
  height: 100%;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  margin: 0;
  font: inherit;
  font-family: var.$font-family-root;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}

body {
  background-color: colors.$web-primary-700;
  font-family: var.$font-family-root;
  font-size: 1rem;
  line-height: 1;
  // padding-top: 50px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;

  /* Scrollbar styles */
  &::-webkit-scrollbar {
    width: 0.375rem;
  }

  &::-webkit-scrollbar-track {
    background-color: colors.$web-primary-700;
  }

  &::-webkit-scrollbar-thumb {
    background-color: colors.$web-primary-700;
    border-radius: 9999px;
    border: 3px solid colors.$web-primary-300;
  }
}
