@use "../base/variables" as var;
@use "../base/colors" as colors;
@use "../commons/common" as common;
/*
 *  Document   : _navbar.scss
 *  Description: This scss file for navbar style classes
 */
.navbar {
  font-family: var.$font-family-root-heavy;
  @include common.border-radius(0);
  box-shadow: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: 0;
  line-height: 55px;
  flex-direction: row;
  align-items: center;
  width: auto;
  position: fixed;
  right: 0;
  transition: all 0.3s ease-in-out;

  .navbar-brand {
    @include common.three-dots-overflow;

    &--text {
      color: colors.$web-primary-400;
      font-size: 32px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 4px;
      font-family: var.$font-family-root-light;
      margin-right: 24px;
    }

    &--v-line {
      border-left: 2px solid colors.$web-neutral-0;
      height: 34px;
      margin-right: 24px;
    }

    &:hover {
      color: colors.$web-primary-400;
      text-shadow: 0px 0px 15px colors.$web-primary-400;
    }
  }

  .navbar-collapse {
    &.in {
      overflow: visible;
    }

    &.collapse {

      &.show {
        background-color: #000;
      }

    }
  }

  .navbar-nav {
    .nav-link {
      color: colors.$web-neutral-0;
      font-size: 15px;
      word-spacing: -2px;
    }

    .nav-link:hover {
      color: colors.$web-primary-400;
      text-shadow: 0px 0px 9px colors.$web-primary-400;
    }
  }

  .nav.navbar-nav {
    display: inline-flex;
    line-height: 55px;
    flex-direction: row;
    align-items: center;
    width: auto;
    position: absolute;
    right: 0;
  }

  .container-navigation {
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;

    &--fixed {
      position: fixed;
      z-index: 2;
      top: 0;
      background: #000;
      transition: all 0.3s ease-in-out;
      overflow: hidden;
      height: 50px;
    }

    &--scroll {
      background: 0 0;
      transition: background 0.4s ease-in-out, padding 0.4s ease-in-out;
      transition-duration: 0.4s, 0.4s;
      transition-timing-function: ease-in-out, ease-in-out;
      transition-delay: 0s, 0s;
      transition-property: background, padding;
      position: static;
      bottom: -164px;
      color: colors.$web-neutral-0;
      height: 80px;
    }

    &--mobile {
      align-items: center;
      justify-content: space-between;
      padding: 0;

      .navbar-brand {
        margin: 0;
        padding-right: 24px;
        padding-left: 24px;
      }

      .navbar-toggler {
        // Ajustes para el botón en modo móvil
      }

      &--expanded {
        overflow: auto;
        height: auto;
        background: #000;
      }
      
      .navbar-brand--v-line {
        display: none;
      }
    }

  }

  .container-fluid,
  .container {
    display: inline-flex;
  }

  .dropdown-menu ul.menu li {
    width: 100%;
  }

  .nav-left-menu {
    margin: 3px 15px;
  }

  .collapse-menu-icon {
    margin-bottom: 0;
    padding-left: 21.5px;
  }

}

nav.navbar {
  min-height: 80px;
}

//     @include transition(all 0.5s);

//   nav,
//   nav .nav-wrapper i,
//   nav a.button-collapse,
//   nav a.button-collapse i {
//     @media screen and (min-width: 601px) {
//       height: 54px;
//       line-height: 54px;
//     }
//   }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.25rem;
  }
}