@media (min-width: 1024px) and (max-width: 1440px) {

  .main-panel--text {
    font-size: calc(3.5rem + 1.5dvw);
    line-height: calc(4rem + 1dvh);
    width: min(50%, 1000px);
  }
  .parallax-container--flex {
    padding-top: calc(10dvh + 1%);
  }
}

@media (min-width: 1440px) {
  .main-panel--text {
    font-size: calc(3.4rem + 2dvw);
    line-height: calc(5rem + 1.5dvh);
    width: min(50%, 750px);
  }
}

@media (max-width: 1920px) {
  .parallax-container--flex {
    padding-top: calc(10dvh + 1%);
  }
}
@media (min-width: 2560px) {
  .main-panel--text {
    width: min(50%, 850px);
  }
}
@media (min-width: 2840px) {
  .main-panel--text {
    width: min(50%, 1100px);
  }
}
