@use "../../styles/base/colors" as colors;


.project {
  color: #ffffff;

   background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;

  &__container {
    padding: var(--navbar-height, 0px) 2rem 2rem 2rem;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }



  &__content {
    color: colors.$web-primary-400;

    gap: 2vh;
    flex-wrap: nowrap;
    flex-direction: column;
    display: flex;
    justify-content: space-between;

    &--header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
    }

  }

  &__title {
    font-size: 3.75rem;
    line-height: 1.2;
  }

  &__date {
    font-size: 0.9rem;
    color: colors.$web-primary-400;
    letter-spacing: 0.1em;

  }


  &__description {
    font-size: 1.2rem;
    line-height: 1.5;
    color: colors.$web-primary-400;
  }


  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 6vw;

    &-item {
      background: colors.$web-primary-700;
      border: 1px solid rgba(218, 255, 251, 0.15);
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

      text-align: center;

      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      padding: 1rem;
      gap: 4px;
      border: 1px solid rgba(218, 255, 251, 0.15);
      border-radius: 2px;


      &-label {
        font-size: 0.9rem;
        text-transform: uppercase;
        margin-bottom: 0.5rem;

        font-weight: 400;
        font-size: 0.813rem;


        opacity: 0.6;


      }

      &-value {
        font-size: 0.813rem;

        &--tech {
          font-size: 0.813rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          justify-content: center;
        }

        .tech-icon {
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
      }
    }
  }


  &__divider {
    width: 100%;
    height: 2px;
    margin: 2rem 0;
    background: linear-gradient(90deg, rgba(221, 221, 221, 0.5) 0%, rgba(238, 238, 238, 0.25) 50%, rgba(255, 255, 255, 0) 100%);


  }

  &__btn {
    color: colors.$web-primary-700;
    text-transform: uppercase;
    text-decoration: none;
    border: 2px solid colors.$web-primary-400;
    padding: 10px 2px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    background: colors.$web-primary-400;
    position: relative;
    transition: color 0.5s ease-in-out;
    overflow: hidden;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -150%;
      width: 120%;
      height: 100%;
      background: colors.$web-primary-700;
      transform: skewX(-25deg);
      transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
      z-index: -1;
    }

    &:hover {
      color: colors.$web-primary-400;
      border-color: colors.$web-primary-400;

      &::before {
        left: -10%;
      }
    }
  }

  &__scroll-indicator {
    margin-top: 2rem; // Espacio entre el botón y la flecha
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .scroll-container {

      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      animation: smoothBounce 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;

      i {
        font-size: 2rem;
        background: transparent;
        color: colors.$web-primary-400;
        border-radius: 50%;
      }
    }
  }

  @keyframes smoothBounce {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

  // Media queries para ajustar espaciado en móvil
  @media (max-width: 768px) {
    .project {
      &__scroll-indicator {
        margin-top: 1rem;

        .scroll-container i {
          font-size: 1.5rem;
        }
      }
    }
  }
  &-gallery {
    min-height: 100vh;
    padding: 2rem 0;
    background: colors.$web-primary-700;
  
    &__container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 2rem;
      display: flex;
      justify-content: center;
    }
    &__image {
      width: 100%;
      max-width: 800px;
      height: auto;
      transition: transform 0.3s ease;
  
      // First image (logo)
      &:first-child {
        max-width: 300px;
        padding-bottom: 2rem;
      }
  
      // Second image (portal-home)
      &:nth-child(2) {
        border-radius: 8px 8px 0 0; // Only top radius
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      }
  
      // Last image
      &:last-child {
        border-radius: 0 0 8px 8px; // Only bottom radius
      }
  
      // Rest of images (dashboard and similar)
      &:nth-child(n+3):not(:last-child) {
        border-radius: 0; // No radius for middle images
      }
    }
    &__grid {
      display: flex;
      flex-direction: column;
      align-items: center; 
      width: 100%;
      max-width: 800px;
      padding: 2rem;
      background-color: white;
      border-radius: 8px;
    }
  
    &__collage {
      width: 100%;
      position: relative;
      padding: 2rem 0;
    
      .collage-item {
        width: 100%;
        transition: all 0.3s ease;
        position: relative;
        margin-bottom: -300px; // Creates the overlap effect
    
        &:nth-child(1) {
          z-index: 4;
        }
    
        &:nth-child(2) {
          z-index: 3;
          margin-left: 2px;
        }
    
        &:nth-child(3) {
          z-index: 2;
          margin-left: 4px;
        }
    
        &:nth-child(4) {
          z-index: 1;
          margin-left: 8px;
          margin-bottom: 0;
        }
    
        img {
          width: 100%;
          height: auto;
          border-radius: 8px;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        }
    
        &:hover {
          transform: translateY(-10px);
          z-index: 10; // Higher z-index to ensure it's above all others on hover
        }
      }
    
      @media (max-width: 768px) {
        .collage-item {
          margin-bottom: -100px;
          
          &:nth-child(2) { margin-left: 20px; }
          &:nth-child(3) { margin-left: 40px; }
          &:nth-child(4) { 
            margin-left: 60px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.tendam {
    color: #ffffff;
  background: linear-gradient(180deg, rgba(6, 9, 24, 0.855) 0%, rgba(6, 9, 24, 0.95) 63.9%, rgba(6, 9, 24, 1) 98%,  rgba(6, 9, 24, 1) 100%), url('/assets/img/work/tendam/background-tendam.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
  }

  &.kiesraad {
    background: linear-gradient(180deg, rgba(6, 9, 24, 0.855) 0%, rgba(6, 9, 24, 0.95) 40.9%, rgba(6, 9, 24, 1) 98%,  rgba(6, 9, 24, 1) 100%), url('/assets/img/work/kiesraad/kiesraad_bg.webp');
    color: #ffffff;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100vh;
  }

  &.crowdsender{
    background: linear-gradient(180deg, rgba(6, 9, 24, 0.855) 0%, rgba(6, 9, 24, 0.95) 40.9%, rgba(6, 9, 24, 1) 98%,  rgba(6, 9, 24, 1) 100%), url('/assets/img/work/crowdsender/bg_crowdsender.webp');
    color: #ffffff;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100vh;
  }
  
}