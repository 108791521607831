@use "../commons/common" as common;
@use "../base/colors" as colors;

/* _card.scss */
.card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
}

.card {
    position: relative;
    width: 100%;
    padding-top: 66.67%;
    background: no-repeat left center;
    background-size: cover;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border-style: solid;
    border-color: var(--border-default, rgba(218, 255, 251, 0.15));
    border-width: 1px;
    align-self: stretch;
    flex-shrink: 0;
    //  height: 513px;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.3s ease, filter 0.3s ease;
    cursor: pointer;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
    }

    &-content {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 1.125rem;

        &--grid {
            display: flex;
            flex-direction: row;
            gap: 32px;
            justify-content: space-between;
            flex-shrink: 0;
            align-content: space-between;
            background: rgba(255, 255, 255, 0.05);
            border-radius: 2px;
            border-style: solid;
            border-color: rgba(255, 255, 255, 0.15);
            border-width: 1px;
            padding: 0.375rem 0.5rem 0.375rem 0.5rem;
            position: relative;
            backdrop-filter: blur(15px);
            align-items: center;

            &__title {
                font-size: 1.5rem;
                font-weight: 300;
                letter-spacing: 1px;
                color: colors.$web-primary-400;
                margin: 0;
            }

            &__subtitle {
                @include common.three-dots-overflow;
                font-size: 13px;
                font-weight: 400;
                letter-spacing: 1.5px;
                color: colors.$web-primary-400;
                text-transform: uppercase;
                margin: 0;

                position: relative;
                cursor: pointer;

            }

        }
    }
}



@media (max-width: 600px) {
    .card-container {
        grid-template-columns: 1fr;
        /* Stacks cards vertically on small screens */
    }
}

.web-panel {

    padding-top: 8rem;
  padding-bottom: 8rem;

    &-card {
        //  padding-bottom: 5rem;
        padding-bottom: 3rem;

        &-title {
            //  font-size: 8rem;
            padding: 0;
            padding-bottom: 1rem;
            font-size: 6rem;
            color: colors.$web-primary-400;
            font-weight: 300;
            line-height: 100%;
        }

        &-subtitle {
            //  font-size: 1.5rem;
            font-size: 1rem;
            color: colors.$web-primary-400;
            opacity: 0.6;
            font-weight: 300;
            line-height: 150%;
        }
    }


}

hr {

    border-width: 0;
    border-style: solid;
    border-bottom-width: 2px;
    border-image: radial-gradient(50% 50% at 50% 50%,
            rgba(23, 107, 135, 0.5) 68.23%,
            rgba(23, 107, 135, 0) 100%) 100;
    margin: 0;
}