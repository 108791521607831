@use "../base/colors" as colors;
/**
 * Common SCSS
 *
 * Can include things like variables and mixins
 * that are used across the project.
*/
 
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($shadowinfo) {
  -webkit-box-shadow: $shadowinfo;
  -moz-box-shadow: $shadowinfo;
  -ms-box-shadow: $shadowinfo;
  box-shadow: $shadowinfo;
}

@mixin transform($transform) {
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}

@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin three-dots-overflow() {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin navbar-link-color($textcolor, $navbarcolor, $opacity) {

  .navbar-brand,
  .navbar-brand:hover,
  .navbar-brand:active,
  .navbar-brand:focus {
    color: $textcolor;
  }

  .nav>li>a:hover,
  .nav>li>a:focus,
  .nav .open>a,
  .nav .open>a:hover,
  .nav .open>a:focus {
    background-color: transparentize($navbarcolor, $opacity);
  }

  .nav>li>a {
    color: $textcolor;
  }

  .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: $textcolor;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
  }

  .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}
 
.custom-tooltip {
  text-transform: uppercase;
  position: absolute;
  width: max-content;
  max-width: 200px;
  background: rgba(0, 0, 0, 0.8);
  color: colors.$web-primary-400 !important;
  padding: 5px;
  border-radius: 4px;
  white-space: normal;
  text-align: left;
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-in-out; 
  .p-tooltip-arrow{
    z-index: -1;  
    position: absolute;
    bottom: -10px; 
    left: 50%;
    margin-left: -5px;  
    border-width: 5px;
    border-style: solid;
    border-color: rgba(218, 255, 251, 0.8)  transparent transparent transparent;  
 
  }
}