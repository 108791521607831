@media (max-width: 768px) {
  .vh-100 {
    height: auto !important;
  }

  .main-panel--text {
    font-size: calc(1.5rem + 3dvw);
    line-height: calc(2rem + 3dvh);
    max-width: calc(100% - 2rem);
  }

  .parallax-container--flex {
    padding-top: 10dvh;
  }
  .right-container {
    &__text {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }
  .right-container-about__text--name {
    font-size: 1.2rem;
  }
  .right-container-about__text--im {
    font-size: 1rem;
  }
}

@media (width: 768px) and (max-height: 1024px) {
  .vh-100 {
    height: auto !important;
  }

  .main-panel--text {
    font-size: calc(3rem + 3dvw);
    line-height: calc(3rem + 3dvh);
    width: min(80%, 1000px);
  }
  .right-container-about__text--name {
    font-size: 3rem;
  }
}

@media (min-width: 600px) and (max-width: 959.98px) {
  .vh-100 {
    height: auto !important;
  }

  .right-container {
    &__text {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }
}

@media (max-width: 430px) {
  .vh-100 {
    height: auto !important;
  }

  .main-panel--text {
    font-size: calc(3rem + 3dvw);
    line-height: calc(2.5rem + 3dvh);
    // padding-top: 1dvh;
  }
}

@media (max-width: 430px) and (min-height: 500px) and (max-height: 737px) {
  .vh-100 {
    height: auto !important;
  }

  .main-panel--text {
    font-size: calc(2rem + 3dvw);
    line-height: calc(2rem + 3dvh);
    width: min(90%, 800px);
  }

  .parallax-container--flex {
    padding-top: calc(10dvh + 3%);
  }
}

@media (max-width: 360px) {
  .vh-100 {
    height: auto !important;
  }

  .main-panel--text {
    font-size: calc(2.5rem + 3dvw);
    line-height: calc(2.5rem + 3dvh);
  }
  .right-container {
    &__text {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
    &-about__text--name {
      font-size: 1rem;
    }
  }
}

@media only screen and (width: 320px) and (max-height: 737px) {
  .vh-100 {
    height: auto !important;
  }

  .parallax-container--flex {
    padding-top: calc(10dvh + 5%);
  }

  .main-panel--text {
    font-size: calc(1.5rem + 3dvw);
    line-height: calc(1.5rem + 3dvh);
  }
  .right-container {
    &__text {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }
}
