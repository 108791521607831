@use "../base/colors" as colors;
.stage {
  width: 100%;
  height: 100%;
  /*   user-select:none; */
}

.stage {
  overflow: hidden;
  background: colors.$web-primary-700;
  transition: 0.5s;
}


/////////////////////
/// 
/// 
/// 
///
///  
/////////////////////

// ------------------------------------------



img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.section {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;

  .container {
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
  }
}
 
.swiper-wrapper {
  flex: none;
  align-items: flex-start;
  display: flex;
}

.swiper-slide {
  flex: none;
  height: clamp(27rem, 63vmin, 39rem);
  width: clamp(18rem, 42vmin, 26rem);

  .people__card {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: clamp(18rem, 42vmin, 26rem);
    background-color: #111b1a;
    border-radius: .375rem;
 
    .people__card__image {
      display: inline-block;
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
      z-index: 2;
      overflow: visible; 
      width: 100%;
      height: 100%;
      max-width: none;
      object-fit: cover; 
      transition: transform 0.7s;
    }

    .people__card__content {
      position: relative;
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      padding: 40px 30px;
      border-radius: .375rem;
      transition: 0.3s;

      .slide__number {
        margin-bottom: 30px;
        opacity: 1;
        font-size: 32px;
        font-weight: 300;
        color: #ebefe3;

        @media (max-width: 1199px) {
          margin-bottom: 20px;
          font-size: 24px;
        }

        @media (max-width: 1199px) {
          font-size: 20px;
        }
      }

      .slide__title {
        margin-bottom: 20px;
        font-size: 3em;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.03em;
        color: #ebefe3;

        @media (max-width: 1199px) {
          font-size: 2.4em;
        }

        @media (max-width: 767px) {
          font-size: 1.92em;
        }
      }

      .slide__subtitle {
        margin-bottom: 30px;
        max-width: 70%;
        color: #ebefe3;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.6;

        @media (max-width: 1199px) {
          font-size: 15px;
          max-width: 100%;
        }

        @media (max-width: 767px) {
          font-size: 14px;
          max-width: 85%;
        }
      }

      .slide__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        border: 1px solid #ebefe3;
        border-radius: .375rem;
        text-decoration: none;
        transition: background-color 0.3s;

        @media (max-width: 767px) {
          padding: 10px 20px;
        }

        &:hover {
          background-color: #ebefe3;

          .slide__btn__text {
            color: #111b1a;
          }

          .slide__btn__icon {
            path {
              color: #111b1a;
            }
          }
        }

        .slide__btn__text {
          margin-right: 5px;
          font-size: 20px;
          font-weight: 500;
          color: #ebefe3;
          transition: color 0.3s;

          @media (max-width: 767px) {
            font-size: 18px;
          }
        }

        .slide__btn__icon {
          width: 15px;
          font-size: 24px;

          @media (max-width: 767px) {
            font-size: 18px;
          }

          path {
            color: #ebefe3;
            transition: 0.3s;
          }
        }
      }
    }

     .slide__gradient {
      position: absolute;
      z-index: 4;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
      overflow: clip;
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
      background-color: rgba(0, 0, 0, 0.438);
      backdrop-filter: blur(4px);
      inset: 0;  
      transition: background-color 2s;
    }
  
  }
 
}
 

 