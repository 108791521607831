@use "../base/colors" as colors;
@use "../base/variables" as var;
@use "../commons/common" as common;

footer {
  width: 100%;
  min-height: 50vh;
  min-height: 50svh;
  line-height: 50px;
  bottom: 0;
  left: 0;
  background-color: colors.$web-primary-700;
  position: relative;
  z-index: 10;

  hr {
    border-width: 0;
    border-style: solid;
    border-bottom-width: 2px;
    border-image: radial-gradient(50% 50% at 50% 50%,
        rgba(74, 255, 201, 0.5) 68.23%,
        rgba(74, 255, 201, 0) 100%) 100;
    margin: 0;
  }
}

.footer {
  &-nav {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    // gap: 45px;
    gap: 10px;
    padding: 42px 0 42px 0;

    .nav-item {
      .nav-link {
        // font-size: 20px;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: colors.$web-neutral-0;
        font-family: var.$font-family-root;

      }
    }
  }

  &-text {
    color: colors.$web-neutral-0;
    text-align: center;
    // font-size: 15px;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.225px;
    float: left;

    &-right{
      padding-right:5%;
    }

    &-left{
      padding-left: 5%;

    }
  }

  &-bottom {
    padding-top: 60px;
    display: flex;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
    align-items:center;
  }

  &-social-media {
    display: flex;
    // gap: 17px;
    gap: 0px;

    .media-icon {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.4s ease-in-out;

      &:hover {
        color: colors.$web-primary-700;
        font-size: 20px;
        text-shadow: 0px 0px 15px colors.$web-primary-400;
      }

    }

  }
}

.footer-brand {
  @include common.three-dots-overflow;

  &--text {
    color: colors.$web-primary-400;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 4px;
    font-family: var.$font-family-root-light;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &:hover {
    color: colors.$web-primary-400;
    text-shadow: 0px 0px 15px colors.$web-primary-400;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}