@use "../base/variables" as var;
h1,
.h1 {
  font-family: var.$font-family-root;
  //   TO DEFINE
}

h2,
.h2 {
  font-size: 3rem;
  line-height: 1.25;
  font-family: var.$font-family-root-text;

  // &.web-panel-title { 
  //   font-size: 8rem;
  //   color: $web-primary-400;
  //   font-weight: 700;
  // } 
}

h3,
.h3 {
  @extend .h1;
  //   TO DEFINE
  //   font-size: 14px;
  //   line-height: 15px;
}

h4,
.h4 {
  @extend .h1;
  //   TO DEFINE
}

h5 {
  @extend .h1;
  //   TO DEFINE
}
 
// @media (min-width: 1024px)
// .lg\:border-neutrals-600 {
//     --tw-border-opacity: 1;
//     border-color: rgb(46 54 79 / var(--tw-border-opacity));
// }
// @media (min-width: 1024px)
// .lg\:border-t-0\.5 {
//     border-top-width: .5px;
// }