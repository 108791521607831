 

@font-face {
  font-family: "Saiba 45 Outline";
  font-style: normal;
  font-weight: normal;
  font-stretch: 100%;
  font-display: swap;
  src: local("Saiba 45 Outline"),
    url("../../assets/fonts/saiba/saiba-45-Outline.woff") format("woff");
}

@font-face {
    font-family: "Saiba 45 Regular";
    font-style: normal;
    font-weight: normal;
    font-stretch: 100%;
    font-display: swap;
    src: local("Saiba 45 Regular"),
      url("../../assets/fonts/saiba/saiba-45-Regular.woff") format("woff");
  }