/*
 *  Document   : index.css
 *  Description: This is a main index scss file for import all scss files.
 *
 *  Structure (with shortCodes):
                 [1. Common ]
                 [2. Fonts ]
                 [3. Components ]
                 [4. Apps]
                 [5. Pages ]
                 [6. Plugins ]
                 [7. UI ]
                 [8. Media Queries ]
                 [9. Browser ]


/*                 [1. Common ]                   			*/
@use 'base' as base;
@use "commons";
/* Importing Bootstrap SCSS file. */
@use "bootstrap";
/*                 [2. Fonts ]                   			*/
@use "fonts";

/*                 [3. Components ]                   */
@use "core";
@use "components";


/*                 [8. Media Queries ]                   */
@use "responsive";